export default {
  "notice": "請如實填寫以下資料，方便我們核對，其中帶*的為必填項",
  "contact_number": "聯繫電話",
  "bank_transfer": "銀行轉帳",
  "e_wallet": "經原本的電子錢包",
  "coupon": "等值優惠劵二維碼",
  "payment": "支付方式",
  "payment_placeholder": "請選擇你付款時所使用的支付方式",
  "reason_for_refund": "退款原因",
  "switch_language": "切換語言",
  "without_delivery": "支付成功但沒出貨",
  "delivery_with_problem": "已取到貨品但有問題",
  "other_reason": "其它原因",
  "octopus": "八達通",
  "ali_weChat": "支付寶 / 微信支付",
  "crypto": "加密貨幣",
  "payment_records": "支付記錄",
  "screen_capture": "截圖 / 相片 (1-3張圖片)",
  "amount_pay_time": "金額，支付時間",
  "click_to_upload": "點擊上傳",
  "payment_amount": "支付金額",
  "payment_time": "支付時間",
  "refund_method": "退款方式",
  "payee_name": "收款人姓名 (大寫英文)",
  "bank_account_number": "銀行帳戶號碼",
  "submit": "提交",
  "site_number": "場地編號",
  "contact_us": "你已成功提交退款申請，請等待我們審核。如有其它査詢，可電郵至：%{email}",
  "invalid_file_type": "文件格式錯誤",
  "errorMessageUnknownError": "未知錯誤",
  "phone_whatsApp_required_tip": "請填寫聯繫電話或WhatsApp其中之一",
  "picture": "圖片",
  "return_to_modify": "返回修改",
  "at_least_one_picture": "請至少上傳一張圖片",
  "site_address": "場地地址",
  "refund_approved": "此退款申請已由管理員審批通過，暫不可修改",
  "site_name": "場地名稱",
  "product_name": "產品名稱",
  "bank_name": "銀行名稱",
  "bank_transfer_tip": "我了解銀行轉帳可能需要 30 天的時間來處理",
  "errorMessageRequiredSelect": "此項是必選項，請勾選",
  "enter_uppercase_english": "請輸入大寫英文",
  "INVALID_PARAMS": "無效的參數",
}