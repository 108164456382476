import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  message,
  Upload,
  Spin,
} from 'antd'
import { UploadOutlined,PlusOutlined } from '@ant-design/icons'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Import (props) {
  const {
    onOk,
    accept,
    action,
    listType,
    maxCount,
    fileList,
    setFileList,
    showUploadList = true,
    buttonText,
    data = {},
    ...rest
  } = props
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = (info) => {
    setFileList(info.fileList)
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {buttonText}
      </div>
    </button>
  );
  return (
    <>
      <Upload
        beforeUpload={
          function (file) {
          const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
          if (!isJpgOrPng) {
            message.error(`文件格式错误`);
            return false;
          }
          return isJpgOrPng
        }}
        action={action}
        listType="picture-card"
        fileList={fileList}
        accept="image"
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
