import { configureStore } from '@reduxjs/toolkit';
import { i18nReducer, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import translations from '../locales';

export const store = configureStore({
  reducer: {
    i18n: i18nReducer
  }
});

let locale = 'zh-tw'
if(['zh-tw','zh-cn','en'].indexOf(navigator.language.toLocaleLowerCase())>-1){
  locale = navigator.language.toLocaleLowerCase()
}

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(locale));
syncTranslationWithStore(store)
